import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { API_BASE_URLS } from "../../utils/constant";
import { WarehouseDataType } from "../../@customTypes/warehouse";
import axiosInstance from "../../utils/axios";

type WarehouseState = {
  isLoading: boolean;
  error: boolean;
  warehouseList: WarehouseDataType[];
};

const initialState: WarehouseState = {
  isLoading: false,
  error: false,
  warehouseList: []
};

const slice = createSlice({
  name: "warehouse",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // COMPANY LISTING
    getWarehouseListSuccess(state, action) {
      state.isLoading = false;
      state.warehouseList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getWarehouseList({
  companyId,
  filterData
}: {
  companyId: number;
  filterData: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.warehouse}/list`,
        filterData
      );
      dispatch(slice.actions.getWarehouseListSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteWarehouse({
  companyId,
  id
}: {
  companyId: number;
  id: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      await axiosInstance.delete(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.warehouse}/${id}/delete`
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editWarehouse({
  companyId,
  warehouseDetails
}: {
  companyId: number;
  warehouseDetails: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.warehouse}/${warehouseDetails?.id}/edit`,
        warehouseDetails
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addWarehouse({
  companyId,
  payload
}: {
  companyId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.warehouse}/add`,
        payload
      );

      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function startLoading() {
  return async () => dispatch(startLoading);
}

export function stopLoading() {
  return async () => dispatch(stopLoading);
}
