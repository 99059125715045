import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { API_BASE_URLS } from "../../utils/constant";
import {
  GrnDetailsDataType,
  GrnEntryDataType
} from "../../@customTypes/grnEntry";
import axiosInstance from "../../utils/axios";
import { ProductDataType } from "../../@customTypes/product";

type GrnEntryState = {
  isLoading: boolean;
  error: boolean;
  success: boolean;
  grnVendorProducts: ProductDataType[];
  grnEntryList: GrnEntryDataType[];
  getGrnNewProduct: GrnDetailsDataType[];
  grnDetailsList: GrnDetailsDataType[];
  selectedGrnEntry: GrnEntryDataType | null;
};

const initialState: GrnEntryState = {
  isLoading: false,
  error: false,
  success: false,
  grnVendorProducts: [],
  grnEntryList: [],
  getGrnNewProduct: [],
  grnDetailsList: [],
  selectedGrnEntry: null
};

const slice = createSlice({
  name: "grnEntry",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // IS SUCCESS
    isSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },
    getGrnVendorProductListSuccess(state, action) {
      state.isLoading = false;
      state.grnVendorProducts = action.payload;
    },
    // GRN ENTRY LISTING
    getGrnEntryListSuccess(state, action) {
      state.isLoading = false;
      state.grnEntryList = action.payload;
    },
    // GRN ENTRY LISTING
    getGrnEntryProductListSuccess(state, action) {
      state.isLoading = false;
      state.getGrnNewProduct = action.payload;
    },
    getSelectedGrnEntrySuccess(state, action) {
      state.isLoading = false;
      state.selectedGrnEntry = action.payload;
    },
    getGrnDetailsListSuccess(state, action) {
      state.isLoading = false;
      state.grnDetailsList = action.payload;
    },
    removeGrnDetailsList(state) {
      state.isLoading = false;
      state.grnDetailsList = [];
    }
  }
});

// Reducer
export default slice.reducer;

export function getGrnEntryById({
  companyId,
  id
}: {
  companyId: number;
  id: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.grn_entry}/${id}`
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getSelectedGrnEntrySuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function getGrnEntryList({
  companyId,
  payload
}: {
  companyId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.grn_entry}/list`,
        payload
      );

      if (response?.error) {
        throw new Error(response?.error);
      } else {
        dispatch(slice.actions.removeGrnDetailsList());
        dispatch(slice.actions.getGrnEntryListSuccess(response.data));
      }
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function getGrnNewProductList({
  companyId,
  payload
}: {
  companyId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.grn_entry}/productlist`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else
        dispatch(slice.actions.getGrnEntryProductListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getGrnVendorProductsList({
  companyId,
  grn_id
}: {
  companyId: number;
  grn_id: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.grn_entry}/${grn_id}/products`
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else
        dispatch(slice.actions.getGrnVendorProductListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteGrnEntry({
  companyId,
  id
}: {
  companyId: number;
  id: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.grn_entry}/${id}/delete`
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editGrnEntry({
  companyId,
  payload
}: {
  companyId: number;
  payload: any;
}) {
  const { id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.grn_entry}/${id}/edit`,
        restPayload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addGrnEntry({
  companyId,
  payload
}: {
  companyId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.grn_entry}/add`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getGrnDetailsList({
  companyId,
  payload
}: {
  companyId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.grn_details}/list`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getGrnDetailsListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function removeGrnDetails({
  companyId,
  id
}: {
  companyId: number;
  id: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.grn_details}/${id}/remove`
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteGrnDetails({
  companyId,
  id
}: {
  companyId: number;
  id: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.grn_details}/${id}/delete`
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editGrnDetails({
  companyId,
  payload
}: {
  companyId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.grn_details}/${payload?.id}/edit`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addInwardsBulk({
  companyId,
  payload
}: {
  companyId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.grn_details}/addbulk`,
        // `http://192.168.1.112:3000/api/company/249/grn_details/addbulk`,
        payload
      );

      if (response?.error) {
        throw new Error(response?.error);
      }
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function addGrnDetails({
  companyId,
  payload
}: {
  companyId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.grn_details}/add`,
        // `http://192.168.1.72:3000/api/company/205/grn_details/add`,

        payload
      );

      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function startLoading() {
  return async () => dispatch(startLoading);
}

export function stopLoading() {
  return async () => dispatch(stopLoading);
}

export function removeError() {
  return async () => dispatch(slice.actions.hasError(false));
}

export function setSuccess(payload: boolean) {
  return async () => dispatch(slice.actions.isSuccess(payload));
}

export function removeGrnDetailsList() {
  return async () => dispatch(slice.actions.removeGrnDetailsList);
}
