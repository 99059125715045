export const getManagementStyle = () => ({
  background: "yellow",
  base_dialog: {
    width: "100%",
    "& .dialog-title": {
      background: "#EEF0FF",
      padding: "24px",
      position: "sticky",
      width: "100%",
      top: "0px",
      zIndex: "2",
      "& h2": {
        fontSize: "1.125rem",
        color: "#212B36"
      }
    },
    "& .dialog-body": {
      paddingInline: "20px",
      zIndex: "1"
    },
    "& .dialog-footer": {
      padding: "10px 44px"
    }
  },

  tableRowBorderBottom: {
    borderBottom: "1px solid rgba(145, 158, 171, 0.24)"
  },

  tableHeadCell: {
    fontWeight: 600,
    fontSize: "1.1rem",
    color: "#637381"
  },

  customListing: {
    "& .MuiTableHead-root": {
      "& .MuiTableCell-root ": {
        paddingLeft: "12px",
        whiteSpace: "nowrap"
      },
      "& .chkbox-cell": {
        paddingLeft: "16px"
      }
    }
    // "& .MuiTableBody-root": {
    //   "& .MuiTableCell-root": {
    //     "&.MuiTableCell-body": {
    //       padding: "10px 13px 5px 13px!important"
    //     }
    //   }
    // }
  },

  input_label: {
    color: "#919eab",
    fontWeight: 500,
    paddingBottom: "10px"
  },

  fileInput: {
    width: "100%",
    position: "relative",
    "& label": {
      // paddingRight: "10px",
      "& .chooseImage-container": {
        border: "1px solid #919eab",
        borderRadius: "8px",
        "&:hover": {
          border: "1px solid #000",
          "& .close-icon-box": {
            display: "flex"
          }
        },
        "& .btnChoose": {
          background: "#efefef",
          padding: "15.5px 14px 15.5px 14px",
          minWidth: "132px",
          borderRadius: "8px 0 0 8px"
        }
      }
    },
    "& .urlField": {
      position: "absolute",
      top: "-15px",
      right: "11px",
      maxWidth: "calc(100% - 142px)",
      whiteSpace: "nowrap",
      overflow: "hidden !important",
      textOverflow: "ellipsis",
      borderRadius: "0 8px 8px 0",
      color: "#96a2af",
      flex: 1,
      "& fieldset": {
        border: "none"
      }
    },

    "&:hover": {
      "& .close-icon-box": {
        display: "flex"
      }
    },

    "& .close-icon-box": {
      position: "absolute",
      right: "15px",
      top: "10px",
      display: "none",
      background: "#fff",
      padding: "4px",
      borderRadius: "20px",
      "& .close-icon": {
        width: "20px",
        background: "#efefef",
        height: "20px",
        borderRadius: "20px",
        padding: "15px",
        cursor: "pointer",
        "&:hover": {
          background: "#e3e3e3",
          transition: "background 0.2s"
        }
      }
    }
  },

  statusToggle: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    border: "2px solid #efefef",
    borderRadius: "50px",

    "& .radio_container": {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      backgroundColor: "#fff",
      width: "100%",
      height: "50px",
      borderRadius: 9999,
      boxShadow: "inset 1px 1px 3px #637381, inset -1px -1px 3px #637381"
    },
    "& input[type='radio']": {
      appearance: "none",
      display: "none",
      "&:checked": {
        "& + label": {
          backgroundColor: "#376dffc7",
          color: "#f1f3f5",
          fontWeight: 900,
          transition: "0.3s",
          borderRadius: "50px"
        }
      }
    },

    "& label": {
      fontFamily: '"Open Sans", sans-serif',
      fontSize: 20,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "inherit",
      width: "48%",
      height: "85%",
      textAlign: "center",
      borderRadius: 9999,
      overflow: "hidden",
      transition: "linear 0.3s",
      color: "#6e6e6edd"
    }
  },

  baseDialogContainer: {
    minWidth: "55%",
    // maxWidth: "100%",
    "@media only screen and (max-width: 700px)": {
      minWidth: "95% !important"
    }
  },
  companyBaseDialogContainer: {
    // minWidth: "1200px",
    maxWidth: "900px",
    "@media only screen and (max-width: 900px)": {
      minWidth: "95% !important"
    }
  },
  customDeleteWidth: {
    minWidth: "35% !important"
  },
  baseDialog_paper: {
    minWidth: "600px",
    "@media only screen and (max-width: 700px)": {
      minWidth: "auto"
    },
    // "& .dialog-title": {
    //   position: "absolute",
    //   width: "100%",
    // },
    "& .dialog-footer": {
      "@media only screen and (max-width: 700px)": {
        justifyContent: "center"
      }
    },
    "& .dialog-body": {
      maxHeight: "600px"
    }
  },

  table_icn: {
    padding: "6px",
    fontSize: "30px",
    borderRadius: "10px",
    color: "#3a8cab",
    marginRight: "0.4rem",
    cursor: "pointer",
    "&:hover": {
      padding: "3px",
      // position: "absolute",
      // width: " 2.2em",
      transition: "all 0.1s"
    }
  },

  icn_view: {
    backgroundColor: "#3a8cab69"
  },
  icn_edit: {
    color: "rgb(17 141 87)",
    backgroundColor: "#518b5a57"
  },
  icn_clone: {
    color: "rgb(183, 110, 0)",
    backgroundColor: "rgb(255 171 0 / 32%)"
  },
  icn_delete: {
    color: "#e36244",
    backgroundColor: "#e3624475"
  },
  icn_mail: {
    color: "#db9a13",
    backgroundColor: "#FEE5B0"
  },
  icn_recipt: {
    color: "#4C8E84",
    backgroundColor: "#4c8e8463"
  },
  icn_cart: {
    backgroundColor: "#385477"
  },

  Order_icn: {
    height: "24px",
    minWidth: "24px",
    lineHeight: "0",
    borderRadius: "6px",
    cursor: "default",
    alignItems: "center",
    whiteSpace: "nowrap",
    display: "inline-flex",
    justifyContent: "center",
    textTransform: "capitalize",
    padding: "0px 6px",
    fontSize: "1 rem",
    fontWeight: "700",
    transition: "all 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
  },
  Order_draft_icn: {
    color: "rgb(99, 115, 129)",
    backgroundColor: "rgba(145, 158, 171, 0.16)"
  },
  Order_placed_icn: {
    color: "rgb(171 58 135)",
    backgroundColor: "rgb(201 0 255 / 18%)"
  },
  Order_paid_icn: {
    color: "rgb(17, 141, 87)",
    backgroundColor: "rgba(34, 197, 94, 0.16)"
  },
  Order_ofd_icn: {
    color: "rgb(183, 110, 0)",
    backgroundColor: "rgba(255, 171, 0, 0.16)"
  },
  Order_del_icn: {
    color: " rgb(57 140 213)",
    backgroundColor: "rgb(112 141 217 / 23%)"
  },
  Order_all_icn: {
    color: "rgb(0 97 183)",
    backgroundColor: "rgb(0 164 255 / 27%)"
  },

  cmsEditor: {
    width: "100%",
    maxWidth: "100%",
    "& .detail-sec": {
      marginBottom: "5px",
      "& .MuiGrid-root": {
        background: "#f7fafc",
        borderRadius: "8px",
        "& .MuiInputBase-root": {
          background: "#fff"
        }
      }
    },
    "& .editor-sec": {
      background: "#f7fafc",
      borderRadius: "8px",
      marginTop: "5px",
      "& .quill": {
        borderRadius: "7px 7px 7px 7px",
        background: "#fff",
        "& .ql-toolbar": {
          borderRadius: "7px 7px 0 0",
          border: "none",
          borderBottom: "2px solid #b7b7b76b",
          paddingBottom: "20px",
          "& .ql-formats": {
            margin: "2px",
            "& button": {
              borderRadius: "7px",
              padding: "3px",
              height: "30px",
              background: "#fdfdfd",
              boxShadow: "2px 2px 6px #c9c9c9",
              margin: "2px",
              marginTop: "12px"
            },
            "& .ql-picker-label": {
              borderRadius: "7px",
              padding: "3px",
              height: "30px",
              background: "#fdfdfd",
              boxShadow: "2px 2px 6px #c9c9c9",
              margin: "2px"
            },
            "& .ql-picker-options": {
              marginTop: "10px",
              borderRadius: "5px",
              marginLeft: "3px"
            }
          }
        },
        "& .ql-container": {
          border: "none",
          minHeight: "320px",
          borderRadius: "0 0 7px 7px"
        }
      }
    }
  },
  imagePreview: {
    width: "200px",
    height: "200px",
    overflow: "hidden",
    borderRadius: "50%",
    border: "3px solid #e1e1e1",
    margin: "20px 10px",
    "@media only screen and (max-width: 700px)": {
      width: "150px",
      height: "150px"
    },
    "& img": {
      width: "100%"
    }
  },
  flexBoxEnd: {
    display: "flex",
    justifyContent: "center",
    alignItems: "end"
  },
  OrderNumMainBox: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    padding: "16px",
    py: 0
  },

  OrderViewFlex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row"
  },
  commonclass: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start"
  }
});
