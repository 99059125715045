import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { API_BASE_URLS } from "../../utils/constant";
import { RoleDataType } from "../../@customTypes/role";
import axiosInstance from "../../utils/axios";

type RoleState = {
  isLoading: boolean;
  error: boolean;
  roleList: RoleDataType[];
  selectedRole: any;
};

const initialState: RoleState = {
  isLoading: false,
  error: false,
  roleList: [],
  selectedRole: null
};

const slice = createSlice({
  name: "role",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // COMPANY LISTING
    getRoleListSuccess(state, action) {
      state.isLoading = false;
      state.roleList = action.payload;
    },
    getSelectedRoleSuccess(state, action) {
      state.isLoading = false;
      state.selectedRole = action.payload;
    },
    removeSelectedRole(state, action) {
      state.isLoading = false;
      state.selectedRole = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getRoleById(id: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.role}/${id}`
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
      dispatch(slice.actions.getSelectedRoleSuccess(response.data));
      return response.data;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getRoleList(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.role}/list`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
      dispatch(slice.actions.getRoleListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteRole(roleId: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.role}/${roleId}/delete`
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editRole(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.role}/${payload?.id}/edit`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addRole(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.role}/create`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editPermissions({ companyId, role_id, ...payload }: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.role}/${role_id}/permission/edit`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function startLoading() {
  return async () => dispatch(startLoading);
}

export function removeSelectedRole() {
  return async () => dispatch(slice.actions.removeSelectedRole(null));
}

export function stopLoading() {
  return async () => dispatch(stopLoading);
}
