import { Icon } from "@iconify/react";
import { Theme } from "@mui/material/styles";
import Box from "@mui/material/Box";

// ----------------------------------------------------------------------

const ICON_SIZE = { width: 20, height: 20 };

export default function TreeView(theme: Theme) {
  return {
    MuiTreeView: {
      defaultProps: {
        defaultCollapseIcon: (
          <Icon icon="ant-design:minus-square-outlined" {...ICON_SIZE} />
        ),
        defaultExpandIcon: (
          <Icon icon="ant-design:plus-square-outlined" {...ICON_SIZE} />
        ),
        defaultEndIcon: (
          <Box
            component={Icon}
            icon="ant-design:close-square-outlined"
            {...ICON_SIZE}
            sx={{ color: "text.secondary" }}
          />
        ),
      },
    },
    MuiTreeItem: {
      styleOverrides: {
        label: { ...theme.typography.body2 },
        iconContainer: { width: "auto" },
      },
    },
  };
}
