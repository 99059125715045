import axios from "axios";
import { navigateToLogin } from "./navtologin";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    charset: "utf-8"
  }
});
export const axiosFileInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  method: "POST",
  headers: {
    // Accept: "application/json",
    "Content-Type": "multipart/form-data"
    // charset: "utf-8",
  }
});
export const axiosDeleteInstance = (data: any) => {
  const token = localStorage.getItem("accessToken");
  return axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      charset: "utf-8",
      Authorization: `${token}`
    },
    data: JSON.stringify(data)
  });
};

// axiosInstance.interceptors.response.use(
//   (response) => response?.data,
//   (error) => error?.response?.data
// );
axiosInstance.interceptors.response.use(
  (response) => response?.data,
  (error) => {
    if (error?.response?.status === 401) {
      navigateToLogin();
    }
    return error?.response?.data;
  }
);

export default axiosInstance;
