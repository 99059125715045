import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { API_BASE_URLS } from "../../utils/constant";
import { SubscriptionDataType } from "../../@customTypes/subscription";
import axiosInstance from "../../utils/axios";

type SubscriptionState = {
  isLoading: boolean;
  error: boolean;
  success: boolean;
  subscriptionDetails: SubscriptionDataType | null;
  subscriptionList: SubscriptionDataType[];
  subscriptionPlanName: SubscriptionDataType[];
  selectedSubscription: SubscriptionDataType | null;
};

const initialState: SubscriptionState = {
  isLoading: false,
  error: false,
  success: false,
  subscriptionDetails: null,
  subscriptionList: [],
  subscriptionPlanName: [],
  selectedSubscription: null
};

const slice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // IS SUCCESS
    isSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },
    // Subscription LISTING
    getSubscriptionListSuccess(state, action) {
      state.isLoading = false;
      state.subscriptionList = action.payload;
    },

    getSelectedCMSSuccess(state, action) {
      state.isLoading = false;
      state.selectedSubscription = action.payload;
    },
    getSelectedsubScriptionName(state, action) {
      state.isLoading = false;
      state.subscriptionPlanName = action.payload;
    },
    removeSelected(state) {
      state.isLoading = false;
      state.selectedSubscription = null;
    }
  }
});

// Reducer
export default slice.reducer;

export function getSubscriptionById({ id }: { id: number }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.cms_page}/${id}`
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        dispatch(slice.actions.getSelectedCMSSuccess(response.data));
        return response.data;
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getSubscriptionName() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `/subscription-plans/plans`
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        dispatch(slice.actions.getSelectedsubScriptionName(response.data));
        return response.data;
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getSubscriptionList(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.subscription}/subscription-list`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getSubscriptionListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteSubscription({ id }: { id: number }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(
        `${API_BASE_URLS.cms_page}/${id}/delete`
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editSubscription({ payload }: { payload: any }) {
  const { id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.cms_page}/${id}/edit`,
        restPayload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addSubscription({ payload }: { payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.cms_page}/add`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function EditSubscriptionDate(payload: any) {
  const { id, subscription_start_date, subscription_end_date, ...restPayload } =
    payload;

  // Ensure the fromDate and toDate values are in the correct format
  const formattedPayload = {
    ...restPayload,
    subscription_start_date: subscription_start_date,
    subscription_end_date: subscription_end_date
  };
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.subscription}/${id}/edit-subscription`,
        formattedPayload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function startLoading() {
  return async () => dispatch(startLoading);
}

export function stopLoading() {
  return async () => dispatch(stopLoading);
}

export function removeError() {
  return async () => dispatch(slice.actions.hasError(false));
}

export function setSuccess(payload: boolean) {
  return async () => dispatch(slice.actions.isSuccess(payload));
}
export function removeSelectedCMS() {
  return async () => dispatch(slice.actions.removeSelected());
}
