import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { API_BASE_URLS } from "../../utils/constant";
import { CustomerDataType } from "../../@customTypes/customer";
import axiosInstance from "../../utils/axios";
import { SyncCustomerDataType } from "../../@customTypes/synccustomer";

type CustomerState = {
  isLoading: boolean;
  isSyncing: boolean;
  isCustomerSyncing: boolean;
  error: boolean;
  customerList: CustomerDataType[];
  synccustomerList: SyncCustomerDataType[];
  selectedCustomer: CustomerDataType | null;
};

const initialState: CustomerState = {
  isLoading: false,
  isSyncing: false,
  isCustomerSyncing: false,
  error: false,
  customerList: [],
  synccustomerList: [],
  selectedCustomer: null
};

const slice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    startCustomerSync(state) {
      state.isCustomerSyncing = true;
    },
    stopCustomerSync(state) {
      state.isCustomerSyncing = false;
    },

    startSyncing(state) {
      state.isSyncing = true;
    },
    //STOP SYNCING
    stopSyncing(state) {
      state.isSyncing = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // COMPANY LISTING
    getCustomerListSuccess(state, action) {
      state.isLoading = false;
      state.customerList = action.payload;
    },
    getSyncCustomer(state, action) {
      state.isLoading = false;
      state.synccustomerList = action.payload;
    },
    getSyncAllCustomer(state, action) {
      state.isLoading = false;
      state.synccustomerList = action.payload;
    },
    getSelectedCustomerSuccess(state, action) {
      state.isLoading = false;
      state.selectedCustomer = action.payload;
    },
    removeSelectedCustomer(state) {
      state.isLoading = false;
      state.selectedCustomer = null;
    }
  }
});

// Reducer
export default slice.reducer;

export function getCustomerById(id: number, companyId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.customer}/${id}`
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
      dispatch(slice.actions.getSelectedCustomerSuccess(response.data));
      return response.data;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function getCustomerList(payload: any, companyId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.customer}/list`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        // dispatch(slice.actions.removeSelectedCustomer());
        dispatch(slice.actions.getCustomerListSuccess(response.data));
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

//this one is only for updating the customers
export function getSyncCustomer(companyId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startSyncing());
    // dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.company}/${companyId}/myob/customer`
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        // dispatch(slice.actions.removeSelectedCustomer());
        dispatch(slice.actions.getSyncCustomer(response.data));
        dispatch(slice.actions.stopSyncing());
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.stopSyncing());
      throw new Error(error.message);
    }
  };
}

//this one is for creating the customers
export function getSyncAllCustomer(companyId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    dispatch(slice.actions.startCustomerSync());
    // dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.company}/${companyId}/myob/sync-customers`
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        // dispatch(slice.actions.removeSelectedCustomer());
        dispatch(slice.actions.getSyncAllCustomer(response.data));
        dispatch(slice.actions.stopCustomerSync());
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.stopCustomerSync());
      throw new Error(error.message);
    }
  };
}

export function deleteCustomer(id: number, companyId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.customer}/${id}/delete`
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editCustomer(payload: any, companyId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.customer}/${payload?.id}/edit`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addCustomer(payload: any, companyId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.customer}/add`,
        payload
      );
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addBulkCustomer({
  companyId,
  payload
}: {
  companyId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.customer}/add/bulk`,
        payload
      );

      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getCustomerInvoice({
  companyId,
  id
}: {
  companyId: number;
  id: number;
}) {
  // const { id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.customer}/${id}/report`
      );
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function getCustomerMailInvoice({
  companyId,
  id
}: {
  companyId: number;
  id: number;
}) {
  // const { id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.customer}/${id}/sendmailreport`
      );
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function startLoading() {
  return async () => dispatch(startLoading);
}

export function stopLoading() {
  return async () => dispatch(stopLoading);
}

export function removeSelectedCustomer() {
  return async () => dispatch(slice.actions.removeSelectedCustomer);
}
