export const getlayoutStyle = () => ({
  authLayout: {
    "& .header": {
      minHeight: "88px"
    },
    "& .auth-wrapper": {
      position: "absolute",
      top: "88px",
      width: "100%",
      minHeight: "calc(100vh - 88px)",
      padding: "20px",
      "& .left-section": {
        paddingLeft: "10px",
        maxHeigh: "500px"
      },
      "& .right-section": {
        "& .title": {}
      }
    }
  },

  form: {
    width: "70%", // Fix IE 11 issue.
    marginTop: "2px",
    "@media (max-width: 820px)": {
      paddingBottom: "50px",
      marginTop: "10px"
    }
  },

  removeLine: {
    textDecoration: "none"
  },

  submit: {
    margin: "20px 0 20px",
    padding: "1px"
  },

  comingsoon: {
    "@keyframes fadeIn": {
      "0%": {
        opacity: 0,
        transform: "translateY(100%)"
      },
      "100%": {
        opacity: 1,
        transform: "translateY(0%)"
      }
    },

    animation: "fadeIn 1000ms ease",

    "& h1": {
      fontSize: "5vw",
      fontFamily: "'Poppins', sans-serif",
      marginBottom: "0",
      lineHeight: "1",
      fontWeight: "700",
      color: "#182e68f5",
      "& dot": {
        color: "#4FEBFE"
      }
    }
  },
  paperPosition: {
    display: "flex",
    alignItem: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    position: "relative",
    backgroundColor: "#7467ef",
    padding: "20px",
    maxWidth: "800px",
    borderRadius: "10px",
    border: "1px solid trasparent",
    top: "30px"
  }

  // webkit: {
  //   webkitAppearance: "none",
  // },
});
