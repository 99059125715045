import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { API_BASE_URLS } from "../../utils/constant";
import { TotalSalesType } from "../../@customTypes/saleschart";
import axiosInstance from "../../utils/axios";
import { TotalCompanyType } from "../../@customTypes/companychart";
import { TotalProductType } from "../../@customTypes/productchart";
import { TotalOrderCustomerWiseType } from "../../@customTypes/TotalOrderCustomerWiseType";
import { TotalSuperSalesDataType } from "../../@customTypes/supersaleschart";
import { SalesOrderComWiseDataType } from "../../@customTypes/salesordercomwise";
import { TotalOrderCustomerWiselimitType } from "../../@customTypes/TotalOrderCustomerWiselimitType";
import { TotalOrderCategoryWiseType } from "../../@customTypes/TotalOrderCategoryWiseType";
import { TotalOrderBrandWiseType } from "../../@customTypes/TotalOrderBrandWiseType";
import { TotalOrderProductWiseType } from "../../@customTypes/TotalOrderProductWiseType";

type ChartState = {
  isLoading: boolean;
  error: boolean;
  TotalSalesChartData: TotalSalesType[];
  TotalProductChartData: TotalProductType[];
  TotalCompanyChartData: TotalCompanyType[];
  TotalOrderCustomerWiseData: TotalOrderCustomerWiseType[];
  TotalOrderCategoryWiseData: TotalOrderCategoryWiseType[];
  TotalOrderBrandWiseData: TotalOrderBrandWiseType[];
  TotalOrderProductWiseData: TotalOrderProductWiseType[];
  TotalOrderCustomerWiseDatalimit: TotalOrderCustomerWiselimitType[];
  TotalSuperSalesData: TotalSuperSalesDataType[];
  SalesOrderComWiseData: SalesOrderComWiseDataType[];
};

const initialState: ChartState = {
  isLoading: false,
  error: false,
  TotalSalesChartData: [],
  TotalProductChartData: [],
  TotalCompanyChartData: [],
  TotalOrderCustomerWiseData: [],
  TotalOrderCategoryWiseData: [],
  TotalOrderBrandWiseData: [],
  TotalOrderProductWiseData: [],
  TotalOrderCustomerWiseDatalimit: [],
  TotalSuperSalesData: [],
  SalesOrderComWiseData: []
};

const slice = createSlice({
  name: "chart",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // SALES CHART
    getTotalSalesSuccess(state, action) {
      state.isLoading = false;
      state.TotalSalesChartData = action.payload;
    },
    //PRODUCT CHART
    getTotalProductChartData(state, action) {
      state.isLoading = false;
      state.TotalProductChartData = action.payload;
    },
    //COMPANY CHART
    getTotalCompanyData(state, action) {
      state.isLoading = false;
      state.TotalCompanyChartData = action.payload;
    },
    //GET ORDER CUSTOMER WISE
    getOrderCustomerWiseData(state, action) {
      state.isLoading = false;
      state.TotalOrderCustomerWiseData = action.payload;
    },
    //top category code--------------
    getOrderCategoryWiseData(state, action) {
      state.isLoading = false;
      state.TotalOrderCategoryWiseData = action.payload;
    },
    //top brand code-----------------
    getOrderBrandWiseData(state, action) {
      state.isLoading = false;
      state.TotalOrderBrandWiseData = action.payload;
    },
    getOrderProductWiseData(state, action) {
      state.isLoading = false;
      state.TotalOrderProductWiseData = action.payload;
    },
    getOrderCustomerWiseDatalimit(state, action) {
      state.isLoading = false;
      state.TotalOrderCustomerWiseDatalimit = action.payload;
    },
    getTotalSuperSalesData(state, action) {
      state.isLoading = false;
      state.TotalSuperSalesData = action.payload;
    },
    getSalesOrderComWiseData(state, action) {
      state.isLoading = false;
      state.SalesOrderComWiseData = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getTotalSales(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.admin}/sales_count`,
        payload
      );

      dispatch(slice.actions.getTotalSalesSuccess(response.data));
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getTotalProducts(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.admin}/products`,
        payload
      );

      dispatch(slice.actions.getTotalProductChartData(response.data));

      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getTotalCompany(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.superadmin}/company_count`,
        payload
      );

      dispatch(slice.actions.getTotalCompanyData(response.data));
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getTotalSuperSales(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.superadmin}/sales`,
        payload
      );

      dispatch(slice.actions.getTotalSuperSalesData(response.data));

      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getOrderCustomerWise(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.admin}/order_customer`,
        payload
      );

      dispatch(slice.actions.getOrderCustomerWiseData(response.data));
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getOrderCategoryWise(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.admin}/top_categories`,
        payload
      );

      dispatch(slice.actions.getOrderCategoryWiseData(response.data));
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getOrderBrandWise(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.admin}/top_brands`,
        payload
      );

      dispatch(slice.actions.getOrderBrandWiseData(response.data));
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getOrderProductWise(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.admin}/top_products`,
        payload
      );

      dispatch(slice.actions.getOrderProductWiseData(response.data));
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getOrderCustomerWiselimit(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        // `http://192.168.1.122:3000/api/company/379/admin/order_graph`,
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.admin}/order_graph`,
        payload
      );

      dispatch(slice.actions.getOrderCustomerWiseDatalimit(response.data));
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function getSalesOrderComWise(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.superadmin}/sales_company`,
        payload
      );

      dispatch(slice.actions.getSalesOrderComWiseData(response.data));
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function startLoading() {
  return async () => dispatch(startLoading);
}

export function stopLoading() {
  return async () => dispatch(stopLoading);
}
