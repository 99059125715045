import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { API_BASE_URLS } from "../../utils/constant";
import { CompanyDataType } from "../../@customTypes/company";
import axiosInstance from "../../utils/axios";

type CompanyState = {
  isLoading: boolean;
  error: boolean;
  success: boolean;
  companyList: CompanyDataType[];
  currentCompany: CompanyDataType | null;
  subscriptionError: boolean;
};

const initialState: CompanyState = {
  isLoading: false,
  error: false,
  success: false,
  companyList: [],
  currentCompany: null,
  subscriptionError: false
};

const slice = createSlice({
  name: "company",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    //subscription end
    subscriptionError(state) {
      state.isLoading = false;
      state.subscriptionError = true;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // IS SUCCESS
    IsSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },
    // COMPANY LISTING
    getCompanyListSuccess(state, action) {
      state.isLoading = false;
      state.companyList = action.payload;
    },
    getCurrentCompanySuccess(state, action) {
      state.isLoading = false;
      state.currentCompany = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getCompanyById(companyId: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.company}/${companyId}`
      );
      if (response.code === 200) {
        dispatch(slice.actions.getCurrentCompanySuccess(response.data));
        localStorage.setItem(
          "myobstatus",
          JSON.stringify(response.data.myobstatus)
        );
      } else if (response.code === 500) {
        dispatch(slice.actions.subscriptionError());
      } else {
        const errorMessage = response.data?.error || "Unexpected error";
        throw new Error(errorMessage);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function getCompanyList(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.getCompanyList}`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getCompanyListSuccess(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteCompany(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(
        `${API_BASE_URLS.company}/${payload}/delete`
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editCompany(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${payload?.id}/edit`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addCompany(payload: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/register`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function startLoading() {
  return async () => dispatch(startLoading);
}

export function stopLoading() {
  return async () => dispatch(stopLoading);
}
export function removeError() {
  return async () => dispatch(slice.actions.hasError(false));
}
export function setSuccess(payload: boolean) {
  return async () => dispatch(slice.actions.IsSuccess(payload));
}
