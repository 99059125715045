import { ReactNode } from "react";
import useAuth from "../hooks/useAuth";
import { Navigate } from "react-router-dom";
import { PATH_PAGE } from "../routes/paths";

type RoleBasedGuardProp = {
  accessibleRoles: String[];
  children: ReactNode | string;
};

export default function RoleBasedGuard({
  accessibleRoles,
  children
}: RoleBasedGuardProp) {
  const { user } = useAuth();
  const currentRole = user?.role.title;
  if (
    !(
      accessibleRoles.includes(currentRole.toUpperCase()) ||
      accessibleRoles.includes(currentRole.toLowerCase())
    )
  ) {
    return <Navigate to={PATH_PAGE.page404} replace />;
  }
  return <>{children}</>;
}
