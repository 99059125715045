import * as React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions
} from "@mui/material";
import { MButton } from "./@material-extend";
import useAuth from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useIsMountedRef from "../hooks/useIsMountedRef";
import { enqueueSnackbar } from "notistack";
import { Stack } from "@mui/system";

export default function SubscriptionEndDialog({ open }: any) {
  const [open1, setOpen1] = React.useState(false);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();

  React.useEffect(() => {
    setOpen1(true);
  }, []);

  const handleClose = () => {
    setOpen1(false);
  };

  const handleLogout = async () => {
    try {
      await logout?.();
      if (isMountedRef.current) {
        navigate("/");
        handleClose();
      }
    } catch (error) {
      enqueueSnackbar("Unable to logout", { variant: "error" });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Stack sx={{ p: 2 }}>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ fontSize: "1.5rem !important" }}
        >
          Subscription Expired
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ marginTop: "20px" }}
          >
            Your subscription has expired. Please consider upgrading your
            subscription plan.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: "0px !important" }}>
          <MButton variant="outlined" color="primary" onClick={handleLogout}>
            Logout
          </MButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
}
