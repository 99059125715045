import useAuth from "../hooks/useAuth";
import { MAvatar } from "./@material-extend";
import { MAvatarProps } from "./@material-extend/MAvatar";
import createAvatar from "../utils/createAvatar";

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();

  return (
    <MAvatar
      src={user?.image?.url}
      alt={`${user?.first_name} ${user?.last_name}`}
      color={
        user?.image?.url
          ? "default"
          : createAvatar(`${user?.first_name} ${user?.last_name}`).color
      }
      {...other}
    >
      {createAvatar(`${user?.first_name} ${user?.last_name}`).name}
    </MAvatar>
  );
}
