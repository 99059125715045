import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
// slices
import userReducer from "./slices/user";
import companyReducer from "./slices/company";
import subscriptionReducer from "./slices/subscription";
import roleReducer from "./slices/role";
import brandReducer from "./slices/brand";
import taxReducer from "./slices/tax";
import customerReducer from "./slices/customer";
import moduleReducer from "./slices/module";
import addressReducer from "./slices/address";
import warehouseReducer from "./slices/warehouse";
import vendorReducer from "./slices/vendor";
import storeReducer from "./slices/store";
import paymentReducer from "./slices/payment";
import categoryReducer from "./slices/category";
import zoneReducer from "./slices/zone";
import visitReducer from "./slices/visit";
import grnentryReducer from "./slices/grnEntry";
import cmsReducer from "./slices/cms";
import generalsettingReducer from "./slices/generalSetting";
import productReducer, { getMyObCreds } from "./slices/product";
import fileReducer from "./slices/file";
import grnEntryReducer from "./slices/grnEntry";
import orderReducer from "./slices/order";
import totalsaleschartReducer from "./slices/chart";
import totalproductschartdataReducer from "./slices/chart";
import totalcompanychartdataReducer from "./slices/chart";
import totalordercustomerwisedataReducer from "./slices/chart";
import totalordercategorywisedataReducer from "./slices/chart";
import totalorderbrandwisedataReducer from "./slices/chart";
import totalorderproductwisedataReducer from "./slices/chart";
import totalordercustomerwisedatalimitReducer from "./slices/chart";
import totalsupersalesdataReducer from "./slices/chart";
import salesordercomwisedataReducer from "./slices/chart";
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: []
};

const rootReducer = combineReducers({
  user: userReducer,
  company: companyReducer,
  subscription: subscriptionReducer,
  role: roleReducer,
  brand: brandReducer,
  tax: taxReducer,
  customer: customerReducer,
  module: moduleReducer,
  address: addressReducer,
  warehouse: warehouseReducer,
  vendor: vendorReducer,
  payment: paymentReducer,
  category: categoryReducer,
  zone: zoneReducer,
  store: storeReducer,
  product: productReducer,
  file: fileReducer,
  grnEntry: grnEntryReducer,
  visit: visitReducer,
  grn_Entry: grnentryReducer,
  cms: cmsReducer,
  generalSetting: generalsettingReducer,
  order: orderReducer,
  totalsaleschart: totalsaleschartReducer,
  totalproductschartdata: totalproductschartdataReducer,
  totalcompanychartdata: totalcompanychartdataReducer,
  totalsupersalesdata: totalsupersalesdataReducer,
  totalordercustomerwisedata: totalordercustomerwisedataReducer, //for super admin
  totalordercategorywisedata: totalordercategorywisedataReducer, //for super admin
  totalorderbrandwisedata: totalorderbrandwisedataReducer, //for  admin
  totalorderproductwisedata: totalorderproductwisedataReducer, //for  admin
  totalordercustomerwisedatalimit: totalordercustomerwisedatalimitReducer, //for super admin
  salesordercomwisedata: salesordercomwisedataReducer, //for super admin
  // myobCredData: myobReducer
});

export { rootPersistConfig, rootReducer };
