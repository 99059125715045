import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { API_BASE_URLS } from "../../utils/constant";
import { StoreDataType } from "../../@customTypes/store"; // Replaced VisitDataType with StoreDataType
import axiosInstance from "../../utils/axios";

type StoreState = {
  isLoading: boolean;
  error: boolean;
  success: boolean;
  storeList: StoreDataType[]; // Replaced visitList with storeList
};

const initialState: StoreState = {
  isLoading: false,
  error: false,
  success: false,
  storeList: [] // Replaced visitList with storeList
};

const slice = createSlice({
  name: "store", // Replaced "visit" with "store"
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // IS SUCCESS
    isSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },
    // STORE LISTING
    getStoreListSuccess(state, action) {
      state.isLoading = false;
      state.storeList = action.payload; // Replaced visitList with storeList
    }
  }
});

// Reducer
export default slice.reducer;

export function getStoreList({
  companyId,
  customerId,
  payload
}: {
  companyId: number;
  customerId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.customer}/${customerId}${API_BASE_URLS.store}/list`,
        // Replaced visit with store
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getStoreListSuccess(response.data)); // Replaced getVisitListSuccess with getStoreListSuccess
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteStore({
  companyId,
  customerId,
  id
}: {
  companyId: number;
  customerId: number;
  id: number;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.customer}/${customerId}${API_BASE_URLS.store}/${id}/delete` // Replaced visit with store
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editStore({
  companyId,
  customerId,
  payload
}: {
  companyId: number;
  customerId: number;
  payload: any;
}) {
  const { id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.customer}/${customerId}${API_BASE_URLS.store}/${id}/edit`, // Replaced visit with store
        restPayload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addStore({
  companyId,
  customerId,
  payload
}: {
  companyId: number;
  customerId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.customer}/${customerId}${API_BASE_URLS.store}/add`, // Replaced visit with store
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function startLoading() {
  return async () => dispatch(startLoading);
}

export function stopLoading() {
  return async () => dispatch(stopLoading);
}

export function removeError() {
  return async () => dispatch(slice.actions.hasError(false));
}

export function setSuccess(payload: boolean) {
  return async () => dispatch(slice.actions.isSuccess(payload));
}
