import NProgress from "nprogress";
import { motion } from "framer-motion";
import { useEffect, useMemo } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { Box } from "@mui/material";
import { SaleswiseIcon } from "../assets/index";

const RootStyle = styled("div")(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  background:
    "linear-gradient(75deg,rgb(22 28 36 / 0%)  0%, rgba(22, 28, 36, 0.48) 100%)"
}));

export default function LoadingScreen({ ...other }) {
  useMemo(() => {
    NProgress.start();
  }, []);

  useEffect(() => {
    NProgress.done();
  }, []);

  return (
    <RootStyle {...other}>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <SaleswiseIcon
          sx={{ marginTop: "20px", width: 75, height: 58, opacity: 0.75 }}
        />
      </Box>

      <Box
        component={motion.div}
        animate={{
          scale: [1.2, 1, 1, 1.2, 1.2],
          rotate: [270, 0, 0, 270, 270],
          opacity: [0.25, 1, 1, 1, 0.25],
          borderRadius: ["25%", "25%", "50%", "50%", "25%"]
        }}
        transition={{ ease: "linear", duration: 3.2, repeat: Infinity }}
        sx={{
          width: 100,
          height: 100,
          borderRadius: "25%",
          position: "absolute",
          border: `solid 3px #0080008c`
        }}
      />

      <Box
        component={motion.div}
        animate={{
          scale: [1, 1.2, 1.2, 1, 1],
          rotate: [0, 270, 270, 0, 0],
          opacity: [1, 0.25, 0.25, 0.25, 1],
          borderRadius: ["25%", "25%", "50%", "50%", "25%"]
        }}
        transition={{ ease: "linear", duration: 3.2, repeat: Infinity }}
        sx={{
          width: 120,
          height: 120,
          borderRadius: "25%",
          position: "absolute",
          border: `solid 8px #40954091`
        }}
      />
    </RootStyle>
  );
}
