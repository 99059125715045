function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = "/auth";
const ROOTS_DASHBOARD = "/app";

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, "/login"),
  verify: path(ROOTS_AUTH, "/verifyOTP"),
  resetPassword: path(ROOTS_AUTH, "/resetPassword"),
  register: path(ROOTS_AUTH, "/register")
};

export const PATH_PAGE = {
  comingSoon: "/coming-soon",
  maintenance: "/maintenance",
  about: "/about-us",
  contact: "/contact-us",
  faqs: "/faqs",
  page404: "/404",
  page500: "/500",
  components: "/components"
};

export const PATH_DASHBOARD = {
  root: path(ROOTS_DASHBOARD, "/dashboard"),
  general: {
    company: path(ROOTS_DASHBOARD, "/list/company"),
    subscription: path(ROOTS_DASHBOARD, "/list/subscription"),
    role: path(ROOTS_DASHBOARD, "/list/role"),
    brand: path(ROOTS_DASHBOARD, "/list/brand"),
    tax: path(ROOTS_DASHBOARD, "/list/tax"),
    customer: path(ROOTS_DASHBOARD, "/list/customer"),
    store: path(ROOTS_DASHBOARD, "/list/store"),
    stores: path(ROOTS_DASHBOARD, "/customer/:customer_id/stores"),
    address: path(ROOTS_DASHBOARD, "/list/address"),
    warehouse: path(ROOTS_DASHBOARD, "/list/warehouse"),
    payment: path(ROOTS_DASHBOARD, "/list/payment"),
    category: path(ROOTS_DASHBOARD, "/list/category"),
    vendor: path(ROOTS_DASHBOARD, "/list/vendor"),
    zone: path(ROOTS_DASHBOARD, "/list/zone"),
    product: path(ROOTS_DASHBOARD, "/list/product"),
    productsDetail: path(ROOTS_DASHBOARD, "/product/:product_id/detail"),
    visit: path(ROOTS_DASHBOARD, "/list/visit"),
    order: path(ROOTS_DASHBOARD, "/list/order"),
    order_detail: path(ROOTS_DASHBOARD, "/order/:order_id/order_detail"),
    grn_Entry: path(ROOTS_DASHBOARD, "/list/grn_Entry"),
    sync: path(ROOTS_DASHBOARD, "/list/sync"),
    // .............................

    grn_EntryAddstock: path(ROOTS_DASHBOARD, "/list/grn_EntryAddstock"),

    generalSetting: path(ROOTS_DASHBOARD, "/generalSetting"),
    cms: path(ROOTS_DASHBOARD, "/cms"),
    cmsEdit: path(ROOTS_DASHBOARD, "/cms/:id"),
    cmsAdd: path(ROOTS_DASHBOARD, "/cms/add"),
    shop: path(ROOTS_DASHBOARD, "/shop")
  },

  user: {
    root: path(ROOTS_DASHBOARD, "/user"),
    profile: path(ROOTS_DASHBOARD, "/user/profile"),
    list: path(ROOTS_DASHBOARD, "/list/user"),
    newUser: path(ROOTS_DASHBOARD, "/user/new"),
    account: path(ROOTS_DASHBOARD, "/user/account")
  },
  settings: {
    root: path(ROOTS_DASHBOARD, "/settings")
  },
  notification: {
    root: path(ROOTS_DASHBOARD, "")
  }
};

export const PATH_DOCS = "https://docs-minimals.vercel.app/introduction";
