import { Fragment, useState } from "react";
import { Icon } from "@iconify/react";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation
} from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material/styles";
import {
  Box,
  List,
  ListItem,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListSubheader,
  BoxProps
} from "@mui/material";
import typography from "../theme/typography";

const ListSubheaderStyle = styled((props: any) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...typography.overline,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  paddingLeft: theme.spacing(5),
  color: theme.palette.text.primary
}));

const ListItemStyle = styled(ListItem)(({ theme }) => ({
  ...typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  padding: "4px 8px 4px 12px !important",
  marginBottom: "10px",
  fontWeight: 700,
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  border: ".5px solid #389ad352",
  "&:hover": {
    backgroundColor: "rgba(145, 158, 171, 0.08)"
  }
}));
const ListItemStyleMain = styled(ListItem)(({ theme }) => ({
  ...typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  padding: "4px 8px 4px 12px !important",
  marginBottom: "3px",
  color: theme.palette.text.secondary,
  borderRadius: "10px",
  "&:hover": {
    backgroundColor: "rgba(145, 158, 171, 0.08)"
  }
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "18px"
});

type NavItemProps = {
  title: string;
  path: string;
  icon?: JSX.Element;
  info?: JSX.Element;
  children?: { title: string; path: string; icon: string }[];
};

function NavItem({ item }: { item: NavItemProps }) {
  const { pathname } = useLocation();
  const { title, path, icon, info, children } = item;
  const isActiveRoot = path
    ? !!matchPath({ path, end: true }, pathname)
    : false;
  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen(!open);
  };

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: "#fff",
    "&:before": { display: "block" }
  };

  const activeSubStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    borderRadius: "10px",
    bgcolor: "#fff"
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          disableGutters
          onClick={handleOpen}
          sx={{
            paddingLeft: "20px !important",
            ...(isActiveRoot && activeRootStyle)
          }}
        >
          <ListItemIconStyle style={{ width: "24px" }}>
            {icon && icon}
          </ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Box
            component={Icon}
            icon={open ? "ep:arrow-down-bold" : "fe:arrow-right"}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path, icon } = item;
              const isActiveSub = path
                ? !!matchPath({ path, end: false }, pathname)
                : false;

              return (
                <ListItemStyleMain
                  disableGutters
                  key={title}
                  // @ts-ignore
                  component={RouterLink}
                  to={path}
                  sx={{
                    paddingLeft: "20px !important",
                    ...(isActiveSub && activeSubStyle)
                  }}
                >
                  <ListItemIconStyle style={{ width: "24px" }}>
                    {icon && icon}
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyleMain>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      disableGutters
      // @ts-ignore
      component={RouterLink}
      to={path}
      sx={{
        paddingLeft: "20px !important",
        ...(isActiveRoot && activeRootStyle)
      }}
    >
      <ListItemIconStyle style={{ width: "24px" }}>
        {icon && icon}
      </ListItemIconStyle>
      <ListItemText disableTypography primary={item.title} />
      {info && info}
    </ListItemStyle>
  );
}

interface NavSectionProps extends BoxProps {
  navConfig: { subheader: string; items: NavItemProps[] }[];
}

export default function NavSection({ navConfig, ...other }: NavSectionProps) {
  return (
    <Box {...other} sx={{ height: "auto", paddingBottom: "50px" }}>
      {navConfig?.map((list) => {
        const { subheader, items } = list;
        return (
          <Fragment key={subheader}>
            <ListSubheaderStyle style={{ paddingLeft: "16px 8px 8px 12px" }}>
              {subheader}
            </ListSubheaderStyle>
            <List key={subheader} disablePadding sx={{ cursor: "pointer" }}>
              {items.map((item: NavItemProps) => (
                <NavItem key={item.title} item={item} />
              ))}
            </List>
          </Fragment>
        );
      })}
    </Box>
  );
}
