import { Suspense, lazy } from "react";
import { Navigate, useRoutes, useLocation } from "react-router-dom";
// layouts
import MainLayout from "../layouts/main";
import DashboardLayout from "../layouts/dashboard";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";

// components
import LoadingScreen from "../components/LoadingScreen";
import AuthLayout from "../layouts/AuthLayout";
import AuthGuard from "../guards/AuthGuard";
import UnAuthGuard from "../guards/UnAuthGuard";
import RoleBasedGuard from "../guards/RoleBasedGuard";
import { MODULE_NAMES, RoleTypes } from "../utils/constant";
import useAuth from "../hooks/useAuth";
import { Box } from "@mui/material";
import PermissionBasedGuard from "../guards/PermissionBasedGuard";
import { useSelector } from "react-redux";
import SubscriptionEndDialog from "../components/SubscriptionEndDialog";
// import Profile from "../pages/profile/Profile";

// ----------------------------------------------------------------------

const Loadable = (Component: any) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isInitialized } = useAuth();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isApp = pathname.includes("/app");

  return (
    <Suspense
      fallback={
        <Box
          sx={{
            ...(!isApp && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed"
            })
          }}
        />
      }
    >
      <>{isInitialized ? <Component {...props} /> : <LoadingScreen />}</>
    </Suspense>
  );
};

export default function Router() {
  const companyState = useSelector((state: any) => state.company);
  return useRoutes([
    {
      path: "auth",
      element: <UnAuthGuard />,
      children: [
        {
          path: "login",
          element: (
            <AuthLayout pageType="login">
              <Login pageType="login" />
            </AuthLayout>
          )
        },
        {
          path: "forgotPassword",
          element: (
            <AuthLayout pageType="forgotPassword">
              <ForgotPassword pageType="forgotPassword" />
            </AuthLayout>
          )
        },
        {
          path: "verifyOTP",
          element: (
            <AuthLayout pageType="verifyOTP">
              <VerifyOTP pageType="verifyOTP" />
            </AuthLayout>
          )
        },
        {
          path: "resetPassword",
          element: (
            <AuthLayout pageType="resetPassword">
              <ResetPassword pageType="resetPassword" />
            </AuthLayout>
          )
        }
      ]
    },

    // Dashboard Routes
    {
      path: "app",
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: "", element: <Navigate to="dashboard" replace /> },
        // { path: "dashboard", element: <Dashboard /> },
        {
          path: "dashboard",
          element: (
            <>
              {companyState.subscriptionError && (
                <SubscriptionEndDialog open={true} />
              )}
              <Dashboard />
            </>
          )
        },
        {
          path: "profile",
          element: (
            <>
              {companyState.subscriptionError && (
                <SubscriptionEndDialog open={true} />
              )}
              <Profile />
            </>
          )
        },
        {
          path: "product",
          element: (
            <>
              {companyState.subscriptionError && (
                <SubscriptionEndDialog open={true} />
              )}
              <Product />
            </>
          )
        },
        {
          path: "cms",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard accessibleRoles={[RoleTypes.SUPER_ADMIN]}>
                  <Cms />
                </RoleBasedGuard>
              )
            },
            {
              path: ":cms_id",
              element: (
                <RoleBasedGuard accessibleRoles={[RoleTypes.SUPER_ADMIN]}>
                  <CMSForm />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: "generalSetting",
          children: [
            {
              path: "",
              element: (
                <RoleBasedGuard accessibleRoles={[RoleTypes.SUPER_ADMIN]}>
                  <GeneralSetting />
                </RoleBasedGuard>
              )
            },
            {
              path: ":gen_set_id",
              element: (
                <RoleBasedGuard accessibleRoles={[RoleTypes.SUPER_ADMIN]}>
                  <GeneralSettingsForm />
                </RoleBasedGuard>
              )
            }
          ]
        },
        {
          path: "list",
          children: [
            {
              path: "company",
              element: (
                <RoleBasedGuard accessibleRoles={[RoleTypes.SUPER_ADMIN]}>
                  <Company />
                </RoleBasedGuard>
              )
            },
            {
              path: "subscription",
              element: (
                <RoleBasedGuard accessibleRoles={[RoleTypes.SUPER_ADMIN]}>
                  <Subscription />
                </RoleBasedGuard>
              )
            },
            {
              path: "role",
              element: (
                <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                  {companyState.subscriptionError ? (
                    <SubscriptionEndDialog open={true} />
                  ) : (
                    <Role />
                  )}
                </PermissionBasedGuard>
              )
            },
            {
              path: "sync",
              element: (
                <PermissionBasedGuard accessibleModule={MODULE_NAMES.sync}>
                  {companyState.subscriptionError ? (
                    <SubscriptionEndDialog open={true} />
                  ) : (
                    <Sync />
                  )}
                </PermissionBasedGuard>
              )
            },

            {
              path: "brand",
              children: [
                {
                  path: "",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <Brand />
                      )}
                    </PermissionBasedGuard>
                  )
                },
                {
                  path: "upload-bulk",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <BulkUploadBrand />
                      )}
                    </PermissionBasedGuard>
                  )
                }
              ]
            },
            {
              path: "tax",
              element: (
                <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                  {companyState.subscriptionError ? (
                    <SubscriptionEndDialog open={true} />
                  ) : (
                    <Tax />
                  )}
                </PermissionBasedGuard>
              )
            },
            {
              path: "user",
              element: (
                <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                  {companyState.subscriptionError ? (
                    <SubscriptionEndDialog open={true} />
                  ) : (
                    <User />
                  )}
                </PermissionBasedGuard>
              )
            },
            {
              path: "customer",
              children: [
                {
                  path: "",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <Customer />
                      )}
                    </PermissionBasedGuard>
                  )
                },
                {
                  path: "upload-bulk",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <CustomerBulkUpload />
                      )}
                    </PermissionBasedGuard>
                  )
                },
                {
                  path: ":customer_id",
                  children: [
                    {
                      path: "edit",
                      element: (
                        <PermissionBasedGuard
                          accessibleModule={MODULE_NAMES.role}
                        >
                          {companyState.subscriptionError ? (
                            <SubscriptionEndDialog open={true} />
                          ) : (
                            <CustomerWizard />
                          )}
                        </PermissionBasedGuard>
                      )
                    }
                  ]
                },
                {
                  path: "add",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <CustomerWizard />
                      )}
                    </PermissionBasedGuard>
                  )
                }
              ]
            },
            {
              path: "address",
              element: (
                <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                  {companyState.subscriptionError ? (
                    <SubscriptionEndDialog open={true} />
                  ) : (
                    <Address />
                  )}
                </PermissionBasedGuard>
              )
            },
            {
              path: "warehouse",
              element: (
                <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                  {companyState.subscriptionError ? (
                    <SubscriptionEndDialog open={true} />
                  ) : (
                    <WarehouseList />
                  )}
                </PermissionBasedGuard>
              )
            },
            {
              path: "payment",
              element: (
                <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                  {companyState.subscriptionError ? (
                    <SubscriptionEndDialog open={true} />
                  ) : (
                    <Payment />
                  )}
                </PermissionBasedGuard>
              )
            },
            {
              path: "vendor",
              element: (
                <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                  {companyState.subscriptionError ? (
                    <SubscriptionEndDialog open={true} />
                  ) : (
                    <Vendor />
                  )}
                </PermissionBasedGuard>
              )
            },
            {
              path: "store",
              element: (
                <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                  {companyState.subscriptionError ? (
                    <SubscriptionEndDialog open={true} />
                  ) : (
                    <Store />
                  )}
                </PermissionBasedGuard>
              )
            },
            {
              path: "category",
              element: (
                <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                  {companyState.subscriptionError ? (
                    <SubscriptionEndDialog open={true} />
                  ) : (
                    <Category />
                  )}
                </PermissionBasedGuard>
              )
            },
            {
              path: "zone",
              element: (
                <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                  {companyState.subscriptionError ? (
                    <SubscriptionEndDialog open={true} />
                  ) : (
                    <Zone />
                  )}
                </PermissionBasedGuard>
              )
            },
            {
              path: "grn_Entry",
              children: [
                {
                  path: "",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <GrnEntry />
                      )}
                    </PermissionBasedGuard>
                  )
                },
                {
                  path: ":grnId",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <GrnProductDetails />
                      )}
                    </PermissionBasedGuard>
                  )
                }
              ]
            },
            {
              path: "grn_EntryAddstock",
              children: [
                {
                  path: "",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <GrnProductDetails />
                      )}
                    </PermissionBasedGuard>
                  )
                },
                {
                  path: ":grnId",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <GrnProductDetails />
                      )}
                    </PermissionBasedGuard>
                  )
                }
              ]
            },
            {
              path: "product",
              children: [
                {
                  path: "",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <Product />
                      )}
                    </PermissionBasedGuard>
                  )
                },
                {
                  path: "upload-bulk",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <BulkUploadProduct />
                      )}
                    </PermissionBasedGuard>
                  )
                },
                {
                  path: ":product_id",
                  children: [
                    {
                      path: "",
                      element: (
                        <PermissionBasedGuard
                          accessibleModule={MODULE_NAMES.role}
                        >
                          {companyState.subscriptionError ? (
                            <SubscriptionEndDialog open={true} />
                          ) : (
                            <ProductDetails />
                          )}
                        </PermissionBasedGuard>
                      )
                    },
                    {
                      path: "edit",
                      element: (
                        <PermissionBasedGuard
                          accessibleModule={MODULE_NAMES.role}
                        >
                          {companyState.subscriptionError ? (
                            <SubscriptionEndDialog open={true} />
                          ) : (
                            <ProductWizard />
                          )}
                        </PermissionBasedGuard>
                      )
                    },
                    {
                      path: "details",
                      element: (
                        <PermissionBasedGuard
                          accessibleModule={MODULE_NAMES.role}
                        >
                          {companyState.subscriptionError ? (
                            <SubscriptionEndDialog open={true} />
                          ) : (
                            <AddProductPrice />
                          )}
                        </PermissionBasedGuard>
                      )
                    }
                  ]
                },
                {
                  path: "add",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <ProductWizard />
                      )}
                    </PermissionBasedGuard>
                  )
                },
                {
                  path: "clone",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <ProductWizard />
                      )}
                    </PermissionBasedGuard>
                  )
                }
              ]
            },
            {
              path: "order",
              children: [
                {
                  path: "",
                  element: (
                    <PermissionBasedGuard accessibleModule={MODULE_NAMES.role}>
                      {companyState.subscriptionError ? (
                        <SubscriptionEndDialog open={true} />
                      ) : (
                        <Order />
                      )}
                    </PermissionBasedGuard>
                  )
                },
                {
                  path: ":order_id",
                  children: [
                    {
                      path: "edit",
                      element: (
                        <PermissionBasedGuard
                          accessibleModule={MODULE_NAMES.role}
                        >
                          {companyState.subscriptionError ? (
                            <SubscriptionEndDialog open={true} />
                          ) : (
                            <OrderDetails />
                          )}
                        </PermissionBasedGuard>
                      )
                    },
                    {
                      path: "view",
                      element: (
                        <PermissionBasedGuard
                          accessibleModule={MODULE_NAMES.role}
                        >
                          {companyState.subscriptionError ? (
                            <SubscriptionEndDialog open={true} />
                          ) : (
                            <OrderView />
                          )}
                        </PermissionBasedGuard>
                      )
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "maintenance", element: <Maintenance /> },
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> }
      ]
    },
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          path: "/",
          element: <Navigate to="/app/dashboard" replace />
        }
      ]
    },
    { path: "*", element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS
// Authentication
const Login = Loadable(lazy(() => import("../pages/authentication/Login")));

const ForgotPassword = Loadable(
  lazy(() => import("../pages/authentication/ForgotPassword"))
);
const VerifyOTP = Loadable(
  lazy(() => import("../pages/authentication/VerifyOTP"))
);
const ResetPassword = Loadable(
  lazy(() => import("../pages/authentication/ResetPassword"))
);

///////////////DASHBOARD///////////////

const Dashboard = Loadable(lazy(() => import("../pages/dashboard")));
const Company = Loadable(lazy(() => import("../pages/management/company")));
const Subscription = Loadable(
  lazy(() => import("../pages/management/subscription"))
);
const Role = Loadable(lazy(() => import("../pages/management/role")));
const Brand = Loadable(lazy(() => import("../pages/management/brand")));
const BulkUploadBrand = Loadable(
  lazy(() => import("../pages/management/brand/BulkUploadBrands"))
);
const Tax = Loadable(lazy(() => import("../pages/management/tax")));
const Customer = Loadable(lazy(() => import("../pages/management/customer")));
const CustomerBulkUpload = Loadable(
  lazy(() => import("../pages/management/customer/forms/CustomerBulkUpload"))
);
const CustomerWizard = Loadable(
  lazy(() => import("../pages/management/customer/CustomerWizard"))
);
const User = Loadable(lazy(() => import("../pages/management/user")));
const Address = Loadable(lazy(() => import("../pages/management/address")));
const WarehouseList = Loadable(
  lazy(() => import("../pages/management/warehouse"))
);
const Category = Loadable(lazy(() => import("../pages/management/category")));
const Vendor = Loadable(lazy(() => import("../pages/management/vendor/index")));
const Store = Loadable(lazy(() => import("../pages/management/stores/index")));
const Payment = Loadable(lazy(() => import("../pages/management/payment")));
const Zone = Loadable(lazy(() => import("../pages/management/zone")));
const GrnEntry = Loadable(lazy(() => import("../pages/management/grnentry")));
const Cms = Loadable(lazy(() => import("../pages/management/cms")));
const CMSForm = Loadable(lazy(() => import("../pages/management/cms/CmsPage")));
const GeneralSetting = Loadable(
  lazy(() => import("../pages/management/generalsetting/index"))
);
const GeneralSettingsForm = Loadable(
  lazy(
    () => import("../pages/management/generalsetting/forms/GeneralSettingsForm")
  )
);
const Order = Loadable(lazy(() => import("../pages/management/order")));
const OrderDetails = Loadable(
  lazy(() => import("../pages/management/order/OrderDetails"))
);
const OrderView = Loadable(
  lazy(() => import("../pages/management/order/OrderView"))
);
const GrnProductDetails = Loadable(
  lazy(
    () => import("../pages/management/grnentry/grnDetails/GrnProductDetails")
  )
);
const Product = Loadable(lazy(() => import("../pages/management/product")));
const BulkUploadProduct = Loadable(
  lazy(
    () => import("../pages/management/product/productForms/BulkUploadProduct")
  )
);
const ProductDetails = Loadable(
  lazy(() => import("../pages/management/product/ProductDetails"))
);
const ProductWizard = Loadable(
  lazy(() => import("../pages/management/product/ProductWizard"))
);
const Sync = Loadable(lazy(() => import("../pages/management/sync/sync")));
const AddProductPrice = Loadable(
  lazy(() => import("../pages/management/product/productForms/AddProductPrice"))
);
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
const Profile = Loadable(lazy(() => import("../pages/profile/Profile")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")));
