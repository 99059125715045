import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { API_BASE_URLS } from "../../utils/constant";
import { GeneralSettingDataType } from "../../@customTypes/genaralSetting";
import axiosInstance from "../../utils/axios";

type GeneralSettingState = {
  isLoading: boolean;
  error: boolean;
  success: boolean;
  generalSetting: GeneralSettingDataType | null;
  generalSettingList: GeneralSettingDataType[];
  generalSettingConfig: GeneralSettingDataType[];
};

const initialState: GeneralSettingState = {
  isLoading: false,
  error: false,
  success: false,
  generalSetting: null,
  generalSettingList: [],
  generalSettingConfig: []
};

const slice = createSlice({
  name: "generalSetting",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // IS SUCCESS
    isSuccess(state, action) {
      state.isLoading = false;
      state.success = action.payload;
    },
    // GENERAL SETTING LISTING
    getGeneralSettingSuccess(state, action) {
      state.isLoading = false;
      state.generalSetting = action.payload;
    },
    getgeneralSettingList(state, action) {
      state.isLoading = false;
      state.generalSettingList = action.payload;
    },
    getgeneralSettingConfigList(state, action) {
      state.isLoading = false;
      state.generalSettingConfig = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getGeneralSettingsById({ id }: { id: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.grn_settings}/${id}`
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        dispatch(slice.actions.getGeneralSettingSuccess(response.data));
        return response.data;
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function getGeneralSettingList({ payload }: { payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.grn_settings}/list`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getgeneralSettingList(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}
export function getGeneralSettingConfigList({ payload }: { payload: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.get(
        `${API_BASE_URLS.grn_settings}/all`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getgeneralSettingConfigList(response.data));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteGeneralSetting({ id }: { id: number }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(
        `${API_BASE_URLS.grn_settings}/${id}/delete`
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editGeneralSetting({ payload }: { payload: any }) {
  const { id, ...restPayload } = payload;
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.grn_settings}/${id}/edit`,
        restPayload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addGeneralSetting({
  // companyId,
  payload
}: {
  // companyId: number;
  payload: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.grn_settings}/add`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function startLoading() {
  return async () => dispatch(startLoading);
}

export function stopLoading() {
  return async () => dispatch(stopLoading);
}

export function removeError() {
  return async () => dispatch(slice.actions.hasError(false));
}

export function setSuccess(payload: boolean) {
  return async () => dispatch(slice.actions.isSuccess(payload));
}
