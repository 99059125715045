import Router from "./routes";
import ThemeConfig from "./theme";
import ThemePrimaryColor from "./components/ThemePrimaryColor";
import ScrollToTop from "./components/ScrollToTop";
import useAuth from "./hooks/useAuth";
import LoadingScreen from "./components/LoadingScreen";
import NotistackProvider from "./components/NotistackProvider";

function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <NotistackProvider>
          {/* <Settings /> */}
          <ScrollToTop />
          {/* <Router /> */}
          {isInitialized ? <Router /> : <LoadingScreen />}
          {/* <Router /> */}
          {/* <LoadingScreen /> */}
        </NotistackProvider>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}

export default App;
