import { Icon } from "@iconify/react";

import { Theme } from "@mui/material/styles";
// @types
import { ColorSchema } from "../../@customTypes/theme";

// ----------------------------------------------------------------------

export default function Alert(theme: Theme) {
  const isLight = theme.palette.mode === "light";

  const standardStyle = (color: ColorSchema) => ({
    color: theme.palette[color][isLight ? "dark" : "light"],
    backgroundColor: theme.palette[color][isLight ? "light" : "dark"],
  });

  const filledStyle = (color: ColorSchema) => ({
    color: theme.palette[color].contrastText,
  });

  const outlinedStyle = (color: ColorSchema) => ({
    color: theme.palette[color][isLight ? "dark" : "light"],
    border: `solid 1px ${theme.palette[color][isLight ? "light" : "dark"]}`,
    backgroundColor: theme.palette[color][isLight ? "light" : "dark"],
  });

  return {
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: <Icon icon="akar-icons:info-fill" />,
          info: <Icon icon="eva:alert-circle-fill" />,
          success: <Icon icon="eva:checkmark-circle-2-fill" />,
          warning: <Icon icon="eva:alert-triangle-fill" />,
        },
      },

      styleOverrides: {
        message: {
          "& .MuiAlertTitle-root": {
            marginBottom: theme.spacing(0.5),
          },
        },
        action: {
          "& button:not(:first-of-type)": {
            marginLeft: theme.spacing(1),
          },
        },

        standardInfo: standardStyle("info"),
        standardSuccess: standardStyle("success"),
        standardWarning: standardStyle("warning"),
        standardError: standardStyle("error"),

        filledInfo: filledStyle("info"),
        filledSuccess: filledStyle("success"),
        filledWarning: filledStyle("warning"),
        filledError: filledStyle("error"),

        outlinedInfo: outlinedStyle("info"),
        outlinedSuccess: outlinedStyle("success"),
        outlinedWarning: outlinedStyle("warning"),
        outlinedError: outlinedStyle("error"),
      },
    },
  };
}
