import { Link as RouterLink } from "react-router-dom";
import { experimentalStyled as styled, Box, Link } from "@mui/material";
import useAuth from "../../hooks/useAuth";
import { PATH_DASHBOARD } from "../../routes/paths";
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import sidebarConfig from "./SidebarConfig";
import { Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }: { theme: any }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    background: "rgba(145, 158, 171, 0.08)"
  }
}));

const openedMixin = (theme: Theme): CSSObject => ({
  width: DRAWER_WIDTH,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  height: "unset !important",
  overflowX: "hidden"
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(9)} + 1px)`,
  height: "unset !important",
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  },
  [theme.breakpoints.up("md")]: {
    width: `calc(${theme.spacing(10)} - 1px)`
  }
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  background: "rgba(145, 158, 171, 0.08)",
  ...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "isHovered"
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open ? { ...openedMixin(theme) } : { ...closedMixin(theme) }),
  "& .MuiDrawer-paper": {
    ...(open ? { ...openedMixin(theme) } : { ...closedMixin(theme) })
  }
}));

type DashboardSidebarProps = {
  isOpenSidebar: boolean;
  onOpenSidebar: VoidFunction;
  onCloseSidebar: VoidFunction;
  onMouseEnter: VoidFunction;
  onMouseLeave: VoidFunction;
  isHovered: boolean;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar
}: DashboardSidebarProps) {
  const { user } = useAuth();

  const permittedModules = new Set(
    user?.role?.read_permissions.map(
      (permission: any) => permission.module_name
    )
  );

  let newSidebar = [...sidebarConfig()];

  const filteredSidebar: any = newSidebar?.map((originalObject: any) => {
    const filteredItems = originalObject?.items?.filter((item: any) => {
      if (item.module_name && permittedModules.has(item.module_name)) {
        return true;
      }
      if (item.children) {
        item.children = item.children?.filter((childItem: any) => {
          return (
            childItem.module_name && permittedModules.has(childItem.module_name)
          );
        });
        return item.children.length > 0;
      } else {
        return item.module_name && permittedModules.has(item.module_name);
      }
    });
    return filteredItems?.length > 0
      ? { ...originalObject, items: filteredItems }
      : null;
  });

  const customScrollbarStyles = {
    "&::-webkit-scrollbar": {
      width: 3,
      height: 6,
      backgroundColor: "transparent"
    },
    "&::-webkit-scrollbar-track": { backgroundColor: "transparent" },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: 6,
      minHeight: 24,
      minWidth: 24
    },
    "&::-webkit-scrollbar-thumb:focus": { backgroundColor: "#adadad" },
    "&::-webkit-scrollbar-thumb:active": { backgroundColor: "#adadad" },
    "&::-webkit-scrollbar-thumb:hover": { backgroundColor: "#adadad" },
    "&::-webkit-scrollbar-corner": { backgroundColor: "transparent" }
  };

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          display: "flex",
          flexDirection: "column",
          height: "90vh",
          overflowY: "auto",
          overflowX: "hidden",
          padding: "0 16px !important",
          ...customScrollbarStyles
        }
      }}
    >
      <Box sx={{ mb: 2, mx: 2.5 }}>
        <Link
          underline="none"
          component={RouterLink}
          to={PATH_DASHBOARD.settings.root}
        ></Link>
      </Box>
      <NavSection
        navConfig={filteredSidebar?.filter((obj: any) => {
          return obj?.items?.length > 0;
        })}
      />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Drawer variant="permanent" open={isOpenSidebar}>
          <DrawerHeader>
            <Box sx={{ position: "relative", left: "-110px" }}>
              <Link
                underline="none"
                component={RouterLink}
                to={PATH_DASHBOARD.root}
              >
                <Logo />
              </Link>
            </Box>

            <IconButton
              onClick={onCloseSidebar}
              style={{ position: "relative", left: "-12px" }}
            >
              {isOpenSidebar ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List
            sx={{ background: "rgba(145, 158, 171, 0.08)" }}
            className={isOpenSidebar ? "open-sidebar" : "close-sidebar"}
          >
            {renderContent}
          </List>
          <Divider />
        </Drawer>
      </Box>
    </RootStyle>
  );
}
