import jwtDecode from "jwt-decode";
import { AuthUser } from "../@customTypes/authentication";
import axios from "./axios";

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (
  tokens: { accessToken: string; refreshToken: string } | null,
  user: AuthUser | null
) => {
  if (tokens) {
    localStorage.setItem("accessToken", tokens.accessToken);
    localStorage.setItem("refreshToken", tokens.refreshToken);
    user && localStorage.setItem("user", JSON.stringify(user));
    axios.defaults.headers.common.Authorization = tokens?.accessToken;
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    localStorage.removeItem("myobstatus");
    delete axios.defaults.headers.common.Authorization;
  }
};

export { isValidToken, setSession };
