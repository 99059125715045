import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../components/Logo";
import { MHidden } from "../components/@material-extend";
import {
  experimentalStyled as styled,
  Avatar,
  Grid,
  Typography
} from "@mui/material";
import LoginImage from "../assets/Login.png";
import SignUpPwdImage from "../assets/otpVerification.png";
import ForgetPwdImage from "../assets/ForgotPwd.png";
import NewPasswordImage from "../assets/newPassword.png";
import LockIcon from "@mui/icons-material/Lock";
import EnhancedEncryptionIcon from "@mui/icons-material/EnhancedEncryption";
import LockPersonIcon from "@mui/icons-material/LockPerson";
import LockResetIcon from "@mui/icons-material/LockReset";
import KeyIcon from "@mui/icons-material/Key";
import { useTheme } from "@mui/material/styles";
import { Stack } from "@mui/system";
import { useClasses } from "../style/classes";

type PageType =
  | "login"
  | "forgotPassword"
  | "verifyOTP"
  | "resetPassword"
  | "register";

const HeaderStyle = styled("header")(({ theme }: { theme: any }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7)
  }
}));

type AuthLayoutProps = { children: ReactNode; pageType: PageType };

export default function AuthLayout({ children, pageType }: AuthLayoutProps) {
  const theme = useTheme();
  const DARKMOD_LIGHT = theme.palette.background.default;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const classes: any = useClasses;

  const AvtarStyle = {
    avatar: {
      margin: theme.spacing(1, 2),
      backgroundColor: PRIMARY_MAIN,
      color: DARKMOD_LIGHT
    },
    iconButton: { color: theme.palette.text.secondary }
  };

  const getPageContent = () => {
    const pageContent: Record<PageType, { title: string; icon: any }> = {
      login: {
        title: "Sign in",
        icon: <LockIcon />
      },
      forgotPassword: {
        title: "Forgot Password",
        icon: <EnhancedEncryptionIcon />
      },
      verifyOTP: {
        title: "Verify OTP",
        icon: <KeyIcon />
      },
      resetPassword: {
        title: "Reset Password",
        icon: <LockResetIcon />
      },
      register: {
        title: "Register",
        icon: <LockPersonIcon />
      }
    };

    return (
      <>
        <Avatar sx={AvtarStyle.avatar}>
          {pageContent[pageType]?.icon || ""}
        </Avatar>
        <Typography component="h1" variant="h5">
          {pageContent[pageType]?.title}
        </Typography>
      </>
    );
  };

  const getImageSource = () => {
    const imageSources: Record<PageType, string> = {
      login: LoginImage,
      forgotPassword: ForgetPwdImage,
      verifyOTP: SignUpPwdImage,
      resetPassword: NewPasswordImage,
      register: SignUpPwdImage
    };
    return imageSources[pageType];
  };

  return (
    <Stack sx={classes.authLayout}>
      <Stack>
        <HeaderStyle className={"header"}>
          <RouterLink to="/">
            <Logo />
          </RouterLink>
          <MHidden width="smDown">
            <Typography variant="body2" sx={{ mt: { md: -2 } }}></Typography>
          </MHidden>
        </HeaderStyle>
      </Stack>
      <Stack
        display="flex"
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        className={"auth-wrapper"}
      >
        <Grid
          container
          sx={{
            ...classes.dFlex,
            ...classes.justifyContentCenter,
            ...classes.alignItemsCenter
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              ...classes.dFlex,
              ...classes.justifyContentCenter,
              ...classes.alignItemsCenter
            }}
            className={"left-section"}
          >
            <img src={getImageSource()} alt="Login" style={{ width: "100%" }} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            sx={{
              ...classes.dFlex,
              ...classes.justifyContentCenter,
              ...classes.alignItemsCenter,
              ...classes.flexColumn
            }}
            className={"right-section"}
          >
            <Stack
              sx={{ ...classes.pBlock4, ...classes.alignItemsCenter }}
              className={"title"}
            >
              {getPageContent()}
            </Stack>
            {children}
          </Grid>
          <Stack sx={{ width: "100%", textAlign: "right" }}>
            <Typography
              variant="caption"
              color="textSecondary"
              sx={{ position: "fixed", bottom: "50px", right: "50px" }}
            >
              Developed <span style={{ color: "red" }}>&#10084;</span> by
              <a
                href="https://www.techcronus.com.au/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                Techcronus Australia
              </a>
            </Typography>
          </Stack>
        </Grid>
      </Stack>
    </Stack>
  );
}
