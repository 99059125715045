import { createSlice } from "@reduxjs/toolkit";
import { UserDataType } from "../../@customTypes/user";
import { dispatch } from "../store";
import axiosInstance from "../../utils/axios";
import { API_BASE_URLS } from "../../utils/constant";
// utils

// ----------------------------------------------------------------------

// type UserState: any;

type UserState = {
  isLoading: boolean;
  error: boolean;
  userList: UserDataType[];
  selectedUser: UserDataType | null;
  totalpage: number;
};

const initialState: UserState = {
  isLoading: false,
  error: false,
  userList: [],
  selectedUser: null,
  totalpage: 0
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // COMPANY LISTING
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },
    getSelectedUserSuccess(state, action) {
      state.isLoading = false;
      state.selectedUser = action.payload;
    },
    setSelectedUser(state, action) {
      state.isLoading = false;
      state.selectedUser = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getUserById({ companyId, id }: { companyId: number; id: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.user}/${id}`
      );
      dispatch(slice.actions.getSelectedUserSuccess(response?.data || {}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserList({
  companyId,
  filterData,
  retunData = false
}: {
  companyId: number;
  filterData: any;
  retunData?: boolean;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.getUserList}`,
        filterData
      );
      if (response?.error) {
        throw new Error(response?.error);
      } else {
        if (retunData) return response.data;
        else dispatch(slice.actions.getUserListSuccess(response?.data || []));
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addNewUser({
  companyId,
  userDetails
}: {
  companyId: number;
  userDetails: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}/register/user`,
        userDetails
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editUserDetails({
  companyId,
  userDetails
}: {
  companyId: number;
  userDetails: any;
}) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const { id, ...restUserDetails } = userDetails;
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.user}/${id}/edit`,
        restUserDetails
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editUserDetails1({
  companyId,
  userDetails,
  formData
}: {
  companyId: number;
  userDetails: any;
  formData: any;
}) {
  return async () => {
    // dispatch(slice.actions.startLoading());
    const { id, ...formData } = userDetails;
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.user}/${id}/edit`,
        formData
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteUser({ companyId, id }: { companyId: number; id: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.user}/${id}/delete`
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function setSelectedUser(payload: any) {
  return async () => dispatch(slice.actions.setSelectedUser(payload));
}

// Actions
// export const { onToggleFollow } = slice.actions;
