import { useState } from "react";
import DashboardSidebar from "./DashboardSidebar";
import DashboardNavbar from "./DashboardNavbar";

function OpenSidebar() {
  const [open, setOpen] = useState(true);
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
    handleToggleSidebar();
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
    handleToggleSidebar();
  };

  const handleToggleSidebar = () => {
    setOpen(!open);
  };

  return (
    <>
      <DashboardNavbar onOpenSidebar={handleToggleSidebar} isOpen={open} />
      <DashboardSidebar
        isOpenSidebar={open}
        onOpenSidebar={handleToggleSidebar}
        onCloseSidebar={handleToggleSidebar}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        isHovered={isHovered}
      />
    </>
  );
}

export default OpenSidebar;
