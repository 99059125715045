import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { experimentalStyled as styled } from "@mui/material";
import { dispatch } from "../../redux/store";
import { getCompanyById } from "../../redux/slices/company";
import useAuth from "../../hooks/useAuth";
import OpenSidebar from "./OpenSidebar";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden"
});

const MainStyle = styled("div")(({ theme }: { theme: any }) => ({
  flexGrow: 1,
  overflow: "auto",
  paddingTop: APP_BAR_MOBILE + 10,
  paddingBottom: theme.spacing(2),
  background: "#f6f9fb",
  minHeight: "100vh",
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 10,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  [theme.breakpoints.up("sm")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2)
  },
  [theme.breakpoints.up("xs")]: {
    paddingTop: APP_BAR_DESKTOP,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2)
  }
}));

export default function DashboardLayout() {
  const auth = useAuth();

  useEffect(() => {
    dispatch(getCompanyById(auth?.user?.company_id));
  }, [auth?.user?.company_id]);

  return (
    <RootStyle>
      <OpenSidebar />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
