import { ReactNode } from "react";
import useAuth from "../hooks/useAuth";
import { Navigate } from "react-router-dom";
import { PATH_PAGE } from "../routes/paths";
import { RoleTypes } from "../utils/constant";

type PermissionBasedGuardType = {
  accessibleModule: String;
  children: ReactNode | string;
};

export default function PermissionBasedGuard({
  accessibleModule,
  children
}: PermissionBasedGuardType) {
  const { user } = useAuth();
  const currentRole = user?.role?.title.toUpperCase();

  const permissionArray = user?.role?.read_permissions?.reduce(
    (prev: any, permission: any) => {
      if (permission?.module_name) {
        return [...prev, permission?.module_name.toUpperCase()];
      } else {
        return [...prev];
      }
    },
    []
  );

  if (
    // permissionArray.indexOf(accessibleModule?.toUpperCase()) < 0 ||
    currentRole === RoleTypes.SUPER_ADMIN
  ) {
    return <Navigate to={PATH_PAGE.page404} replace />;
  }

  return <>{children}</>;
}
