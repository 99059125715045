import { createSlice } from "@reduxjs/toolkit";
import { dispatch } from "../store";
import { API_BASE_URLS } from "../../utils/constant";
import { TaxDataType } from "../../@customTypes/tax";
import axiosInstance from "../../utils/axios";

type TaxState = {
  isLoading: boolean;
  error: boolean;
  taxList: TaxDataType[];
};

const initialState: TaxState = {
  isLoading: false,
  error: false,
  taxList: []
};

const slice = createSlice({
  name: "tax",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // COMPANY LISTING
    getTaxListSuccess(state, action) {
      state.isLoading = false;
      state.taxList = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export function getTaxList(payload: any, companyId: any) {
  return async (dispatch: any) => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.tax}/list`,
        payload
      );

      if (response == null || response.data.length === 0) {
        throw new Error("Response is null or zero");
      }

      if (response?.error) {
        throw new Error(response.error);
      }

      dispatch(slice.actions.getTaxListSuccess(response.data));
      return response;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteTax(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.tax}/${payload}/delete`
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editTax(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.tax}/${payload?.id}/edit`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addTax(payload: any, companyId: any) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(
        `${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.tax}/add`,
        payload
      );
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function startLoading() {
  return async () => dispatch(startLoading);
}

export function stopLoading() {
  return async () => dispatch(stopLoading);
}
