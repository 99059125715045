import { getlayoutStyle } from "./layout.style";
import { getGeneralStyle } from "./general.style";
import { getManagementStyle } from "./management.style";

//@ts-ignore
export const useClasses = {
  ...getGeneralStyle(),
  ...getlayoutStyle(),
  ...getManagementStyle()
};
// export const useClasses = makeStyles((theme?: any) => ({
//   ...getGeneralStyle(),
//   ...getlayoutStyle(),
//   ...getManagementStyle(),
// }));
