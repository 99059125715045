// material
import { Box, BoxProps } from "@mui/material";

// ----------------------------------------------------------------------

export default function SaleswiseIcon({ width, height, ...other }: BoxProps) {
  return (
    <Box {...other}>
      <svg
        width="76"
        height="53"
        viewBox="0 0 76 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.56398 5.84492C5.19957 8.97278 11.4533 20.7143 17.4184 31.9265C23.3835 43.1387 28.4346 52.3298 28.6751 52.2817C28.9156 52.2817 32.1868 46.459 35.9872 39.3371L42.8663 26.4407L41.7598 24.5159C41.1344 23.4572 38.9697 19.415 36.9493 15.4691L33.2451 8.39533H38.2481H43.2992L45.3196 12.1006C46.4261 14.1698 47.4844 16.0466 47.7249 16.2391C48.0617 16.5759 56.3839 1.46591 56.3839 0.503487C56.3839 0.359124 48.1579 0.214761 38.1038 0.214761C28.0497 0.214761 19.8237 0.311003 19.8237 0.407245C19.8237 0.551608 22.8543 6.32613 26.5585 13.3037C30.2626 20.2812 33.2932 26.2001 33.2932 26.4888C33.2932 27.5475 28.8194 35.3912 28.4346 35.0062C28.2421 34.8138 23.9608 26.9219 18.9578 17.5383L9.91392 0.455366L5.19957 0.311003L0.533325 0.16664L3.56398 5.84492Z"
          fill="#1F0A4E"
        />
        <path
          d="M57.2979 17.7789C52.1506 27.4512 47.8211 35.3431 47.6768 35.3431C47.5325 35.3431 47.0033 34.4769 46.5223 33.4182C44.9829 30.0016 44.6943 30.0979 42.2409 34.5731L40.028 38.6634L43.7321 45.641C45.8007 49.4906 47.5806 52.6666 47.7249 52.6666C47.9173 52.6666 75.0489 1.9471 75.5299 0.647835C75.6262 0.40723 73.6538 0.214746 71.1523 0.214746H66.5823L57.2979 17.7789Z"
          fill="#1F0A4E"
        />
      </svg>
    </Box>
  );
}
