import { ReactNode } from "react";
import { Icon } from "@iconify/react";
import { SnackbarProvider } from "notistack";
import { alpha } from "@mui/material/styles";
import { Box } from "@mui/material";
import { ColorSchema } from "../@customTypes/theme";

type SnackbarIconProps = { icon: string; color: ColorSchema };

function SnackbarIcon({ icon, color }: SnackbarIconProps) {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        justifyContent: "center",
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16)
      }}
    >
      <Icon icon={icon} width={24} height={24} />
    </Box>
  );
}

type NotistackProviderProps = { children: ReactNode };

function NotistackProvider({ children }: NotistackProviderProps) {
  return (
    <SnackbarProvider
      dense
      maxSnack={5}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      iconVariant={{
        success: (
          <SnackbarIcon icon={"carbon:checkmark-filled"} color="success" />
        ),
        error: <SnackbarIcon icon={"ic:baseline-info"} color="error" />,
        warning: (
          <SnackbarIcon icon={"tabler:alert-triangle-filled"} color="warning" />
        ),
        info: <SnackbarIcon icon={"carbon:checkmark-filled"} color="info" />
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

export default NotistackProvider;
