import SvgIconStyle from "../components/SvgIconStyle";

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/static/mainIcons/${name}.svg`}
    sx={{ width: "100%", height: "100%" }}
  />
);

export const ICONS: Record<string, JSX.Element> = {
  brand: getIcon("Brand"),
  company: getIcon("building"),
  category: getIcon("Category"),
  cms: getIcon("cms"),
  companymanagement: getIcon("CompanyManagement"),
  customer: getIcon("Customer"),
  dashboard: getIcon("dashboard11"),
  order: getIcon("Order"),
  product: getIcon("Product"),
  address: getIcon("ProductManagement"),
  role: getIcon("Role"),
  settings: getIcon("settings"),
  grn_Entry: getIcon("StockInward"),
  subscription: getIcon("subscription"),
  tax: getIcon("Tax"),
  user: getIcon("User"),
  usermanagement: getIcon("UserManagement"),
  vendor: getIcon("Vendor"),
  warehouse: getIcon("Warehouse"),
  zone: getIcon("Zone"),
  sync: getIcon("Sync")
};
